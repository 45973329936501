export const environment = {
  production: false,
  twitchApiUrl: 'https://api.twitch.tv/helix/',
  twitchAuthApiUrl: 'https://id.twitch.tv/oauth2/token',
  authApiUrl: '',
  meepikUSers: [
    'floloclo',
    'pitithappy',
    'swiss_jim',
    'lucidqg',
    'morganenlive',
    'ledaronyoo',
    'nostal',
    'asfax',
    'valyaris',
    'nakatine_',
    'soulravenn',
    'emyllianna',
    'jim_twb',
  ],
  clientId: 'kl9i31t7h0x2emqk503w0icsfx6hnu',
  clientSecret: '2dvalcuy0v0n1egzqz4gzb8o55j2tw',
  authunUrl: 'https://auth.review.meepik.com',
  webUIBack: 'https://back.review.meepik.com',
};
